import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

// import { Badge } from 'reactstrap';
import img1 from "../images/atmos-1.png";
import img2 from "../images/atmos-2.png";
import img3 from "../images/atmos-3.png";
import img4 from "../images/atmos-4.png";
import img5 from "../images/atmos-5.png";

const PortfolioPage = () => (
  <Layout>
    <SEO
      title="Javier Suzuki | Design & Front-end Development Portfolio - Design System"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `application`, `ai`, `artificial-intelligence`, `ml`, `machine-learning`, `react`]}
    />
    <div className="portfolio-header">
      <h1>AI-ML Platform</h1>
      <h5>Product Design</h5>
      <p>UI Design for an AI-ML platform that activates organisations internal customers data at scale using specialised algorithms to learn continuously from customer interactions by enabling different machine learning models, comparing their performance to measure their success and recommend the ideal next-best-actions.</p>
      <p>Built for organisations running customer-facing digital services who want to use ethical AI for automated decision-making.</p>
      <p>Made with React (React-boilerplate), Antd, Styled-components and Highcharts.</p>
    </div>
    {/* <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://wushka.com.au" target="_blank" rel="noopener noreferrer">View Project</a>
    </div> */}
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        <figcaption className="figheading">Login Screen</figcaption>
        <img src={img1} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Activity Detail Page</figcaption>
        <img src={img2} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Activity Detail Page - Models Performance Comparison</figcaption>
        <img src={img3} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Activity Detail Page - Models Performance Comparison</figcaption>
        <img src={img4} alt=""/>
      </figure>
      <figure className="portfolio-sample">
        <figcaption className="figheading">Create New Activity Form</figcaption>
        <img src={img5} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
